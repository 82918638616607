.featured-cultivators {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background-color: rgba(97, 166, 14,0.2);
    padding-top: 40px;
    padding-bottom: 40px;
}

.cultivators-container {
    width: 1000px;
}

.featured-cultivators .swiper-pagination {
    bottom: 0;
}

.featured-cultivators .swiper-pagination .swiper-pagination-bullet-active {
    background-color: black;
}

.swiper {
    height: 850px;
}

.featured-cultivators .swiper .swiper-button-next,
.featured-cultivators .swiper .swiper-button-prev
{
    color: black;
}

.cult-header {
    font-size: 2.5rem;
}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    color: #fff;
    background-color: #fff;
    margin: 15px;
    font-size: 4em;
    border: 10px solid rgba(97, 166, 14,0.4);
    border-radius: 1.5rem;
}

.item-banner {
    background-color: black;
    position: relative;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item-content {
    width: 100%;
    border-top: 10px solid rgba(97, 166, 14,0.4);
    color: black;
    font-size: 35px;
}

.item-title {
    font-weight: bold;
    padding-top: 30px;
}


.item-description {
    font-size: 20px;
    color: black;
    height: 350px;
    padding: 30px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.cult-button {
    background-color: var(--and-green);
    border-color: var(--and-green);
    margin-bottom: 20px;
    width: 125px;
}

.cult-button > a {
    color:white;
    text-decoration: none;
}

.cult-button:hover {
    color:white;
    text-decoration: none;
    background-color: var(--and-green);
    border-color: var(--and-green);
    filter:brightness(90%);
}

@media screen and (max-width: 1000px) {
    .swiper {
        width: 640px;
    }
}

@media screen and (max-width: 640px){
    .swiper {
        width: 350px;
    }

    .swiper-item {
        width: 300px
    }

    .swiper-item .item-description {
        height: 300px;
        font-size: 16px;
        padding-top: 0;
    }

    .featured-cultivators .swiper .swiper-button-next
    {
        padding-left: 25px;
    }

    .featured-cultivators .swiper .swiper-button-prev
    {
        padding-right: 30px;
    }

    .swiper-item {
        border-radius: 1.5rem;
        border: 5px solid rgba(97, 166, 14,0.4);
    }

    .item-content {
        border-width: 5px;
    }

    .featured-cultivators .swiper-pagination {
        bottom: 40px;
    }
}